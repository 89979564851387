import React from "react"
import { graphql } from "gatsby"
import { injectIntl } from "gatsby-plugin-intl"
import { useMediaQuery } from "@material-ui/core"
import { makeStyles, useTheme } from "@material-ui/core/styles"
import Container from "@material-ui/core/Container"
import Grid from "@material-ui/core/Grid"
import BackgroundImage from "gatsby-background-image"
import SEO from "../components/seo"
import MainToolbar from "../components/main-toolbar"
import Layout from "../components/layout"
import LineTitle from "../components/LineTitle"
import Typography from "@material-ui/core/Typography"
import Img from "gatsby-image"
import { Helmet } from "react-helmet"
import ExpansionPanel from "@material-ui/core/ExpansionPanel"
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary"
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import Button from "@material-ui/core/Button"

const useStyles = makeStyles((theme) => ({
  "@global": {
    body: {
      backgroundColor: "#000",
    },
  },
  mainContainer: {
    padding: "0 !important",
    paddingBottom: "120px",
    backgroundColor: "#202020",
    [theme.breakpoints.down("md")]: {
      paddingBottom: "60px",
    },
  },
  backgroundImg: {
    margin: 0,
    padding: 0,
    paddingBottom: theme.spacing(5),
    [theme.breakpoints.up("sm")]: {
      minHeight: "600px",
    },
    [theme.breakpoints.down("md")]: {
      backgroundPosition: "right",
      height: "280px",
    },
  },
  bannerGrid: {
    flexDirection: "row",
    ["@media (max-width:600px)"]: {
      paddingBottom: "0px",
      height: "42vh",
    },
    [theme.breakpoints.up("sm")]: {
      minHeight: 600,
    },
  },
  bannerLeft: {
    display: "flex",
    justifyContent: "flex-start",
    paddingLeft: 20,
    [theme.breakpoints.down("md")]: {
      paddingLeft: 0,
    },
  },
  bodyText: {
    fontSize: "1.1rem",
    textAlign: "left",
    color: "white",
    maxWidth: 350,
    whiteSpace: "pre-line",
    paddingBottom: 20,
  },
  bodyText2: {
    fontSize: "1.1rem",
    textAlign: "left",
    color: "white",
    maxWidth: 500,
    [theme.breakpoints.down("md")]: {
      paddingLeft: "5%",
    },
    whiteSpace: "pre-line",
    paddingBottom: 20,
  },

  introHeader: {
    textAlign: "left",
    paddingTop: 0,
    paddingBottom: "1em",
    fontWeight: 300,
    maxWidth: 350,
    color: "white",
    fontSize: "2.2rem",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.5rem",
    },
  },
  imageContainer: {
    [theme.breakpoints.up("md")]: {
      alignSelf: "center",
    },
    [theme.breakpoints.down("sm")]: {
      marginTop: 60,
    },
  },
  titleContainer: {
    [theme.breakpoints.down("sm")]: {
      paddingTop: "auto",
      paddingBottom: "auto",
    },
  },
  tooLongtitle: {
    color: "white",
    ["@media (min-width:2040px)"]: {
      fontSize: "4rem",
    },
    ["@media (max-width:1440px)"]: {
      fontSize: "3rem",
    },
    ["@media (max-width:600px)"]: {
      fontSize: "1.5rem",
    },
  },
  tooLongSubheader: {
    color: "#828282",
    textTransform: "uppercase",
    align: "left",
    marginTop: "20px",
    width: "750px",
    fontSize: "1.5rem",
    ["@media (max-width:600px)"]: {
      fontSize: "1rem",
      width: "270px",
    },
  },
  sectionContainer: {
    paddingTop: 120,
    paddingBottom: 80,
    [theme.breakpoints.down("sm")]: {
      paddingTop: 40,
      paddingBottom: 40,
    },
  },
  sectionContainerSmall: {
    paddingTop: 0,
    paddingBottom: 0,
    [theme.breakpoints.down("sm")]: {
      paddingTop: 10,
    },
  },
  sectionAccordions: {
    paddingBottom: 80,
    paddingLeft: "12%",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "5%",
    },
    "@media (min-width:1400px)": {
      paddingLeft: "13%",
    },
    "@media (min-width:1600px)": {
      paddingLeft: "14%",
    },
    "@media (min-width:1800px)": {
      paddingLeft: "15%",
    },
  },
  expansion: {
    whiteSpace: "pre-line",
    marginTop: 20,
    boxShadow: "none!important",
    border: "none!important",
    "&:before": {
      display: "none!important",
    },
    width: "85%",
    [theme.breakpoints.down("sm")]: {
      width: "95%",
    },
  },
  rootSummary: {
    width: "100%",
  },
  expansionDetails: {
    color: "rgba(0, 0, 0, 0.54)",
  },
  contentSummary: {
    color: "rgba(0, 0, 0, 0.54)",
  },
  accordionHeader: {
    textAlign: "left",
    paddingLeft: 12,
    color: "white",
    fontSize: "1.3rem",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.2rem",
    },
  },
  arrowDown: {
    color: "white",
  },
  moreButton: {
    paddingLeft: "3px",
    "&:hover": {
      backgroundColor: "#2F041E",
    },
  },
  bodyTextAcco: {
    fontSize: "1.1rem",
    textAlign: "left",
    color: "white",
    // whiteSpace: "pre-line",
  },
  bookNowButton: {
    marginTop: 30,
    color: "#fff!important",
    fontSize: "14px!important",
    backgroundColor: "#929B91",
    borderRadius: "40px!important",
    padding: "1.0em 4em!important",
    textDecoration: "none!important",
    "&:hover": {
      textDecoration: "none!important",
      opacity: "0.5!important",
      backgroundColor: "#929B91!important",
    },
    [theme.breakpoints.down("xs")]: {
      visibility: "hidden",
      marginTop: "134px!important",
      marginBottom: "24px!important",
      // padding: "0.8em 3em!important",
      padding: "0.8em 1em!important",
    },
  },
}))

// <SEO
//   lang={intl.formatMessage({ id: "global.lang" })}
//   title={intl.formatMessage({ id: "fillers.SEO" })}
//   description={intl.formatMessage({ id: "fillers.SEO" })}
//   path={location.pathname}
// />

const Beautician = ({ intl, data, location }) => {
  const classes = useStyles()
  const theme = useTheme()
  const t = (id) => intl.formatMessage({ id })
  const banner = data?.bannerImage?.childImageSharp.fluid
  const bannerS = data?.bannerImageS?.childImageSharp.fluid
  const smallScreen = useMediaQuery(theme.breakpoints.down("xs"))
  const mediumScreen = useMediaQuery(theme.breakpoints.down("md"))

  const treatementos = [
    {
      title: t("beautician.treatments.spm.title"),
      body: t("beautician.treatments.spm.body"),
    },
    {
      title: t("beautician.treatments.brows.title"),
      body: t("beautician.treatments.brows.body"),
    },
    {
      title: t("beautician.treatments.ombreBrows.title"),
      body: t("beautician.treatments.ombreBrows.body"),
    },
    {
      title: t("beautician.treatments.phibrowsMicroblading.title"),
      body: t("beautician.treatments.phibrowsMicroblading.body"),
    },
    {
      title: t("beautician.treatments.eyeLiner.title"),
      body: t("beautician.treatments.eyeLiner.body"),
    },
    {
      title: t("beautician.treatments.lips.title"),
      body: t("beautician.treatments.lips.body"),
    },
    {
      title: t("beautician.treatments.medischePigment.title"),
      body: t("beautician.treatments.medischePigment.body"),
    },
    {
      title: t("beautician.treatments.browsStyling.title"),
      body: t("beautician.treatments.browsStyling.body"),
    },
    {
      title: t("beautician.treatments.menBrows.title"),
      body: t("beautician.treatments.menBrows.body"),
    },
  ]

  return (
    <Layout>
      {/* <Helmet>
        <script
          src="https://schedule.clinicminds.com/assets/widget/widget.js"
          type="text/javascript"
        ></script>
      </Helmet> */}
      <MainToolbar black />
      <Container maxWidth={"xl"} className={classes.mainContainer}>
        <BackgroundImage
          fluid={mediumScreen ? bannerS : banner}
          className={classes.backgroundImg}
          backgroundPosition={"right"}
          backgroundColor={`#333`}
        >
          <Grid container spacing={0} className={classes.bannerGrid}>
            <Grid item xs={12} sm={6} className={classes.bannerLeft}>
              <Container>
                <LineTitle
                  titleContainer={classes.titleContainer}
                  header={t("beautician.title")}
                  subheader={t("beautician.subtitle")}
                  titleStyle={classes.tooLongtitle}
                  lightDecoLine
                  subHeaderStyle={classes.tooLongSubheader}
                />
              </Container>
            </Grid>
            <Grid item xs={12} sm={6}></Grid>
          </Grid>
        </BackgroundImage>

        {smallScreen && (
          <>
            <Grid
              container
              spacing={12}
              className={classes.sectionContainer}
              // direction={smallScreen ? "column-reverse" : "row"}
            >
              <Grid item xs={12} sm={6} align="center">
                <Typography
                  component="h2"
                  variant="h3"
                  className={classes.introHeader}
                >
                  {t("beautician.introTitle")}
                </Typography>
                <p className={classes.bodyText}>{t("beautician.introText")}</p>
                <Button
                  className={classes.bookNowButton}
                  data-leadbox-popup="vsjnFD76SH9pFuxzdjLoom"
                  data-leadbox-domain="kooldigital.lpages.co"
                >
                  Book consultation
                </Button>
                {/* <a
                  href="https://schedule.clinicminds.com/?clinic=a8f5f0f3-1e41-11e9-881b-0a599d16f52a&service=b210ed63-6406-11e9-881b-0a599d16f52a"
                  className="clinicminds-scheduler-button"
                  style={{
                    backgroundColor: "#929B91",
                    textDecoration: "none",
                    fontWeight: "normal",
                    fontSize: "16px",
                    padding: "16px 24px",
                    marginTop: "80px",
                    marginRight: "120px",
                  }}
                >
                  BOOK APPOINTMENT
                </a> */}
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
                align="center"
                className={classes.imageContainer}
              >
                <Img
                  fluid={data.front.childImageSharp.fluid}
                  alt="Make up for lips"
                  // className={classes.sectionImages}
                />
              </Grid>
            </Grid>

            <Grid container spacing={12} className={classes.sectionContainer}>
              <Grid item xs={12} sm={6} align="center">
                <Typography
                  component="h2"
                  variant="h3"
                  className={classes.introHeader}
                >
                  {t("beautician.teamMonicaTitle")}
                </Typography>
                <p className={classes.bodyText}>
                  {t("beautician.teamMonicaText")}
                </p>
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
                align="center"
                className={classes.imageContainer}
              >
                <Img
                  fluid={data.monica.childImageSharp.fluid}
                  alt="Monica Jacome"
                  // className={classes.sectionImages}
                />
              </Grid>
            </Grid>
          </>
        )}
        {!smallScreen && (
          <>
            <Grid container spacing={12} className={classes.sectionContainer}>
              <Grid
                item
                xs={12}
                sm={6}
                align="center"
                className={classes.imageContainer}
              >
                <Img
                  fluid={data.front.childImageSharp.fluid}
                  alt="Make up for lips"
                />
              </Grid>
              <Grid item xs={12} sm={6} align="center">
                <Typography
                  component="h2"
                  variant="h3"
                  className={classes.introHeader}
                >
                  {t("beautician.introTitle")}
                </Typography>
                <p className={classes.bodyText}>{t("beautician.introText")}</p>
                <Button
                  className={classes.bookNowButton}
                  data-leadbox-popup="vsjnFD76SH9pFuxzdjLoom"
                  data-leadbox-domain="kooldigital.lpages.co"
                >
                  Book consultation
                </Button>
                {/* <a
                  href="https://schedule.clinicminds.com/?clinic=a8f5f0f3-1e41-11e9-881b-0a599d16f52a&service=b210ed63-6406-11e9-881b-0a599d16f52a"
                  className="clinicminds-scheduler-button"
                  style={{
                    backgroundColor: "#929B91",
                    textDecoration: "none",
                    fontWeight: "normal",
                    fontSize: "16px",
                    padding: "16px 24px",
                    marginTop: "80px",
                    marginRight: "120px",
                  }}
                >
                  BOOK APPOINTMENT
                </a> */}
              </Grid>
            </Grid>
            <Grid container spacing={12} className={classes.sectionContainer}>
              <Grid item xs={12} sm={6} align="center">
                <Typography
                  component="h2"
                  variant="h3"
                  className={classes.introHeader}
                >
                  {t("beautician.teamMonicaTitle")}
                </Typography>
                <p className={classes.bodyText}>
                  {t("beautician.teamMonicaText")}
                </p>
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
                align="center"
                className={classes.imageContainer}
              >
                <Img
                  fluid={data.monica.childImageSharp.fluid}
                  alt="Monica Jacome"
                  // className={classes.sectionImages}
                />
              </Grid>
            </Grid>
          </>
        )}
        <Grid container spacing={12} className={classes.sectionContainerSmall}>
          <Grid item xs={12} sm={6} align="center">
            <Typography
              component="h2"
              variant="h3"
              className={classes.introHeader}
            >
              {t("beautician.treatementIntroTitle")}
            </Typography>
            <p className={classes.bodyText}>
              {t("beautician.treatementIntroText")}
            </p>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            align="center"
            // className={classes.imageContainer}
          >
            <div
              style={{
                display: "flex",
                height: "60px",
              }}
            ></div>
          </Grid>
        </Grid>
        <div className={classes.sectionAccordions}>
          {treatementos.map((t) => (
            <ExpansionPanel
              classes={{
                root: classes.expansion,
              }}
            >
              <ExpansionPanelSummary
                classes={{
                  content: classes.contentSummary,
                  root: classes.rootSummary,
                }}
                id="Acco header"
                className={classes.moreButton}
                expandIcon={<ExpandMoreIcon className={classes.arrowDown} />}
              >
                <Typography
                  component="h2"
                  variant="subtitle2"
                  className={classes.accordionHeader}
                >
                  {t.title}
                </Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails className={classes.expansionDetails}>
                <p style={{ color: "white" }} className={classes.bodyTextAcco}>
                  {t.body}
                </p>
              </ExpansionPanelDetails>
            </ExpansionPanel>
          ))}
        </div>
      </Container>
    </Layout>
  )
}

export default injectIntl(Beautician)

export const query = graphql`
  query {
    bannerImage: file(relativePath: { eq: "beautician-33.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2400, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    bannerImageS: file(relativePath: { eq: "beautician-34.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2400, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    front: file(relativePath: { eq: "permanent-lip-make-up-3.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 960, quality: 96) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    monica: file(relativePath: { eq: "monica-jacome-2.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 960, quality: 96) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

//Material UI break points:
// xs (extra-small): 0px or larger
// sm (small): 600px or larger
// md (medium): 960px or larger
// lg (large): 1280px or larger
// xl (extra-large): 1920px or larger
